import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

function Instagram({ bgColor = 'white' }) {
  const { allInstaNode: { edges: posts } } = useStaticQuery(graphql`
    query {
      allInstaNode(limit: 10, sort: { fields: timestamp, order: DESC }) {
        edges {
          node {
            id
            original
            timestamp
            thumbnails {
              src
              config_width
              config_height
            }
          }
        }
      }
    }
  `);

  return (
    <div className={`flex overflow-x-auto py-4 border-t border-b bg-${bgColor}`}>
      <Spacer />
      {posts.map(({ node: post }) => <InstaPost post={post} key={post.id} />)}
      <Spacer />
    </div>
  );
}
Instagram.propTypes = {
  bgColor: PropTypes.string,
};

const Spacer = () => (<div className="h-full" style={{ minWidth: '0.75rem' }}>&nbsp;</div>);

const InstaPost = ({ post }) => (
  <a
    className="block border-4 border-purple border-opacity-25 rounded m-2"
    href={`https://instagram.com/p/${post.id}`}
    target="_blank"
    rel="noreferrer"
  >
    <div className="w-56 h-56 bg-center bg-cover" style={{ backgroundImage: `url(${post.thumbnails[1].src})` }}>
    </div>
  </a>
);
InstaPost.propTypes = {
  post: PropTypes.object.isRequired
};

export default Instagram;
