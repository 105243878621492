import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { useForm } from 'react-hook-form';

const inputClasses = "w-full border border-gray-400 focus:border-yellow rounded placeholder-gray-400 px-3 py-3";

function ContactForm() {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSubmit = (data) => {
    setIsSuccess(false);
    setIsError(false);
    setIsLoading(true);

    axios
      .post('https://tinytotspt.com/library/mail.php', data)
      .then(({ submitted }) => submitted ? setIsSuccess(true) : setIsError(false))
      .then(() => setIsLoading(false));
  };

  return (
    <div className="bg-gray-200">
      <div className="py-20 container max-w-2xl mx-auto">
        <div className="flex items-center">
          <div className="w-full px-8 lg:px-4 xl:px-8 mb-8 lg:mb-0">
            <div className="p-6 md:p-10 bg-white shadow rounded">
              <div className="font-serif text-2xl text-center">Get in touch</div>
              <hr className="mt-4 mb-8" />
              <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="mb-6">
                  <ContactFormLabel errors={errors} field="name" />
                  <input type="text" name="name" placeholder="Name" className={inputClasses} ref={register()} />
                </fieldset>
                <fieldset className="mb-6">
                  <ContactFormLabel errors={errors} field="email" />
                  <input type="email" name="email" placeholder="Email" className={inputClasses} ref={register({ required: true })} />
                </fieldset>
                <fieldset className="mb-6">
                  <ContactFormLabel errors={errors} field="phone" />
                  <input type="text" name="phone" placeholder="Phone number" className={inputClasses} ref={register({ required: true })} />
                </fieldset>
                <fieldset className="mb-6">
                  <ContactFormLabel errors={errors} field="message" />
                  <textarea name="message" placeholder="Message" rows="4" className={inputClasses} ref={register({ required: true })} />
                </fieldset>
                <fieldset className="text-right">
                  <input
                    className="bg-purple font-bold text-white rounded px-6 py-2 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
                    disabled={isLoading}
                    type="submit"
                    value={isLoading ? 'Please wait...' : 'Send'}
                  />
                </fieldset>
              </form>
              {isSuccess && (
                <div className="bg-blue-100 border border-blue-400 rounded text-blue-600 text-center text-sm px-4 py-3 mt-6">
                  Thank you for reaching out to us. We will get back to you very soon.
                </div>
              )}
              {isError && (
                <div className="bg-red-100 border border-red-400 rounded text-red-600 text-center text-sm px-4 py-3 mt-6">
                  Invalid input... All fields are mandatory!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ContactFormLabel({ errors, field }) {
  return (
    <label className="flex items-center justify-between mb-1">
      <span className="capitalize">{field}<sup className="text-red-700">*</sup></span>
      {errors[field] && <span className="text-xs text-red-700">Required</span>}
    </label>
  );
}
ContactFormLabel.propTypes = {
  errors: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
};

export default ContactForm;
