import React from 'react';

import Layout from 'src/components/layout';
import SEO from 'src/components/seo';

import Banner from 'src/components/banner';
import Footer from 'src/components/footer';
import Instagram from 'src/components/instagram';

import ContactFormSection from 'src/components/forms/contact';

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[`TinyTots PT`, `Shruti Pradhan`]}
        title="Contact"
      />
      <Banner>
        <span className="border-b-2 border-yellow">Contact</span>
      </Banner>
      <ContactFormSection />
      <Instagram />
      <Footer />
    </Layout>
  );
}

export default ContactPage;
