import React from 'react';
import PropTypes from 'prop-types';
import BannerImage from 'src/images/banner.jpg';

function Banner({ isHomePage = false, children }) {
  return (
    <div className="bg-purple bg-cover bg-center text-purple text-center relative"
      style={{ backgroundImage: `url(${BannerImage})` }}
    >
      <div className={`container mx-auto flex items-center justify-center ${isHomePage ? 'py-20 md:py-40' : 'py-20'}`}>
        <div className="relative bg-white shadow p-12 rounded w-full max-w-xl mx-8">
          <h2 className="relative text-2xl md:text-4xl font-serif font-light italic z-10">
            {children}
          </h2>

          <BannerBorder style={{ top: '-36px', bottom: '-36px', left: '-16px' }} />
          <BannerBorder style={{ top: '-16px', bottom: '-16px', right: '-16px' }} />
        </div>
      </div>
    </div>
  );
}
Banner.propTypes = {
  isHomePage: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const BannerBorder = ({ style }) => (
  <div className={`absolute w-full flex-auto border-2 border-white rounded`} style={style}></div>
);
BannerBorder.propTypes = {
  style: PropTypes.object.isRequired
};

export default Banner;
