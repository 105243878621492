import React from 'react';

import InstagramImage from 'src/images/social/instagram.inline.svg';
import PinterestImage from 'src/images/social/pinterest.inline.svg';

function Footer() {
  return (
    <footer className="bg-white shadow p-8">
      <div className="text-center text-gray-700 text-sm">
        TinyTots PT &copy; {new Date().getFullYear()}. All rights reserved.
      </div>
      <div className="text-center text-sm my-4">
        <a href="https://blog.tinytotspt.com/privacy-policy" target="_blank" rel="noreferrer" className="text-gray-600 border-b">
          Privacy policy
        </a>
      </div>
      <div className="flex justify-center mx-auto mt-4">
        <a href="https://instagram.com/tinytotspt" target="_blank" rel="noreferrer" className="block w-8 mx-2 text-purple">
          <InstagramImage className="w-full fill-current" />
        </a>
        <a href="https://www.pinterest.com/tinytotspt/_saved/" target="_blank" rel="noreferrer" className="block w-8 mx-2 text-purple">
          <PinterestImage className="w-full fill-current" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
